import { makeStyles } from "@mui/styles";
import netdev from "../images/netdev.png";
import featured from "../images/featured.jpg";
import { Divider } from "@mui/material";
import { ReactComponent as GreenCheck } from "../images/greencheck.svg";
import { ReactComponent as Apply } from "../images/apply.svg";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DomainIcon from "@mui/icons-material/Domain";

const useStyles = makeStyles({
  containerfluid: {
    minWidth: "100%",
    backgroundImage: `url(${featured})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  icon: {
    fontSize: "15px !important",
  },
  container: {
    width: "90%",
    margin: "0rem auto",
    padding: "0 0 3rem 0",
    // backgroundColor: "#062F61",
    color: "white",

    backgroundBlendMode: "multiply",
  },
  ul: {
    fontFamily: "cursive",
  },
  cards: {
    display: "flex",
    textAlign: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",
    margin: "20px 0px 0px 0px",
    "@media screen and (max-width:1024px)": {
      display: "grid",
    },
    "@media screen and (min-width: 320px) and (max-width: 767px)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  card: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "5px",
    color: "black",
    padding: "10px",
    fontFamily: "math",
    width: "30%",
    "@media screen and (min-width: 320px) and (max-width: 767px)": {
      width: "90%",
    },
  },
  card_1: {
    display: "flex",
  },
  img_netdev: {
    height: "90px",
    "& img": {
      height: "90px",
    },
  },
  img_card: {
    fontSize: "x-small",
    textAlign: "left",
    "& p": {
      color: "#3092A8",
    },
    "& h2": {
      "& svg": {
        marginLeft: "8px",
      },
    },
    "& h5": {
      fontSize: "12px",
      fontWeight: "500",
      margin: "0",
      margin: '0.5rem 0',
    },   
  },
  container_1: {
    textAlignLast: "center",
    padding: "20px 0px 0px 0px",
  },
  container_2: {
    textWrap: "nowrap",
    overflow: "scroll",
    "&::-webkit-scrollbar ": {
      display: "none",
    },
    "& ul": {
      listStyleType: "none",
      display: "flex",
      gap: "20px",
      placeContent: "center",
      minWidth: "fit-content",
      padding: "0",
    },
    "& ul li": {
      border: "0.5px solid white",
      backgroundColor: "#124179",
      borderRadius: "20px",
      padding: "5px 20px",
      color: "white",
      alignContent: "center",
      "&:hover": {
        backgroundColor: "white",
        color: "black",
      },
    },
  },
  more: {
    textAlign: "center",
    padding: "30px 0px 20px 0px",
    "& button": {
      width: "100px",
      height: "40px",
      backgroundColor: "white",
      color: "black",
      fontFamily: "math",
    },
  },
  dflexend: {
    display: "flex",
    alignItems: "flex-start",
  },
  lower: {
    "& span": {
      display: "flex",
      gap: "20px",
      "& p": {
        backgroundColor: "#e4e4e7",
        width: "70px",
        borderRadius: "20px",
        padding: "3px",
        fontSize: "xx-small",
      },
    },
    "& div": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& h3": {
        fontSize: "smaller",
      },
      "& button": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#FFA826",
        color: "white",
        width: "110px",
        height: "30px",
        borderRadius: "5px",
        border: "0px",
        "& svg": {
          marginRight: "10px",
        },
      },
    },
  },
});

function FindJobs() {
  const classes = useStyles();
  const jobData = [
    {
      id:'1',
      category: "FMCG - Processed Food Products",
      title: "Business Development Manager - HoReCa",
      location: "Hyderabad, Jaipur, Chandigarh",
      department: "Sales",
      experience: "Min. 5-6 Years in HoReCa",
      type: "Full Time",
      salary: "Up to 10-15.00 LPA",
    },
    {
      id:'2',
      category: "FMCG",
      title: "Senior Executive - R&D",
      location: "Delhi",
      department: "Research & Development",
      experience: "Min. 3-5 Years in B2B & HoReCa",
      type: "Full Time",
      salary: "Up to 6 to  12 LPA",
    },
    {
      id:'3',
      category: "Cranes and Machines",
      title: "Executive to Manager-Sales(Crane)",
      location: "Jaipur,Vadodara/Ahmedabad,Bangalore,Delhi NCR,Chennai,Raipur,Jamshedpur,Hyderabad",
      department: "Sales",
      experience: "2 to 10 years",
      type: "Full Time",
      salary: "7 Lpa to 20 Lpa",
    },
    {
      id:'4',
      category: "Machines",
      title: "Executive to Manager-Sales(Laser cutting machines)",
      location: "Chandigarh/Panchkula/Mohali,Delhi,Mumbai & Pune,Indore/Bhopal",
      department: "Sales",
      experience: "2 to 10 years",
      type: "Full Time",
      salary: "7 Lpa to 19 Lpa",
    },
    {
      id:'5',
      category: "Machines",
      title: "Executive to Manager-Sales( Stone cutting processing machines )",
      location: "Odisha,West Bengal,Khammam & Karimnagar,Bangalore,Himmatnagar/Ahmedabad,Rajsamand & Jaipur",
      department: "Sales",
      experience: "1 to 8 years",
      type: "Full Time",
      salary: "7 Lpa to 16 Lpa",
    },
    {
      id:'6',
      category: "Marble and stone",
      title: "Executive Accounts",
      location: "Oman",
      department: "Accounts",
      experience: "1 to 6 Years",
      type: "Full Time",
      salary: "Negotiable",
    },
    {
      id:'7',
      category: "Marble and stone",
      title: "Executive Accounts",
      location: "Oman",
      department: "Production",
      experience: "3 to 9 Years",
      type: "Full Time",
      salary: "Negotiable",
    },
    {
      id:'8',
      category: "Retail",
      title: "Lead-Visual Merchandiser",
      location: "Mumbai",
      department: "Visual Merchandiser",
      experience: "Min 10 Years",
      type: "Full Time",
      salary: "30 to 40 Lpa",
    },
    {
      id:'9',
      category: "Retail",
      title: "Executive to Manager Designer -Ethnic wear,Western wear,kidswear,Denim",
      location: "Mumbai",
      department: "Designer",
      experience: "7 to 18 Years",
      type: "Full Time",
      salary: "10 to 25 Lpa",
    },
    {
      id:'10',
      category: "Retail",
      title: "Executive to Lead Buyer",
      location: "Mumbai",
      department: "Buyer",
      experience: "2 to 15 Years",
      type: "Full Time",
      salary: "7 to 20 Lpa",
    },
    {
      id:'11',
      category: "Marble and stone",
      title: "Procurement Officer/Stores Manager",
      location: "Oman",
      department: "Purchase/Store",
      experience: "3 years of Experience in Quartz/Manufacturing Setup/Paints/Textiles",
      type: "Full Time",
      salary: "Negotiable",
    },
    { 
      id:'12',
      category: "Marble and stone",
      title: "QUALITY CONTROL MANAGER",
      location: "Oman",
      department: "QUALITY CONTROL /assurance",
      experience: "Minimum 3-4 years of Experience in Quartz/Granite/Marble Industry",
      type: "Full Time",
      salary: "Negotiable",
    },
  ];
  
  return (
    <>
      <div className={classes.containerfluid}>
        <div className={classes.container}>
          <div className={classes.container_1}>
            <h3>Find Jobs</h3>
          </div>
          <div className={classes.container_2}>
            <ul>
              <li>ALL</li>
              <li>FMCG</li>
              <li>Retail</li>
              <li>Cranes and Machines</li>
              <li>Marble and stone</li>
            </ul>
          </div>
          <div className={classes.cards}>
            {jobData.map((job, index) => (
              <div key={index} className={classes.card}>
                <div className={classes.card_1}>
                  <div className={classes.img_card}>
                    <p>{job.category}</p>
                    <h2>
                      {job.title} <GreenCheck />
                    </h2>
                    <h5 className={classes.dflexend}>
                      <LocationOnIcon className={classes.icon} />
                      {job.location}
                    </h5>
                    <h5 className={classes.dflexend}>
                      <DomainIcon className={classes.icon} />
                      <b>Department : </b>
                       { job.department}
                    </h5>
                    <h5 className={classes.dflexend}>
                      <CalendarMonthIcon className={classes.icon} />
                      {job.experience}
                    </h5>
                  </div>
                </div>
                <div className={classes.lower}>
                  <span>
                    <p>{job.type}</p>
                  </span>
                  <Divider />
                  <div>
                    <h3>{job.salary}</h3>
                    <button>
                      <Apply /> Apply Now
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default FindJobs;
